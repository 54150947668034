import React from "react";
import {Seo} from "components/seo";

import { graphql } from "gatsby";
import { globalQuery } from "types/graphql";

import ImageContent from "components/sections/imageContent";
import ListTitles from "components/sections/listTitles";
import TabMethod from "components/sections/tabMethod";
import ArrowTitle from "components/sections/arrowTitle";
import {StaticImage} from "gatsby-plugin-image";
import TitleImageIntro from "components/sections/titleImageIntro";

export default function Method({ data }: { data: globalQuery }) {
  const {
    allMarkdownRemark: { edges: sectionsData },
  } = data;

  return (
    <>
      {sectionsData.map(
        ({ node: { frontmatter: elem, html } }, index: number) => {
          if (elem.format == "title") {
            return (
              <React.Fragment key={index}>
                <Seo title={elem.metaTitle as string} description={elem.metaDescription} />
                <TitleImageIntro title={elem.title} content={html}>
                  <div key={index} className="transition-image row-start-1 row-start-1 col-start-8 col-span-full opacity-20 " data-aos="custom">
                    <StaticImage
                      layout="fullWidth"
                      aspectRatio={1.2/1}
                      objectFit={"cover"}
                      alt="Bureau équipe La Fabrique VingtCinq"
                      src={'../../static/method/VingtCinq-Fabrique-digitale-developpeur.jpg'}
                    />
                    <span className="transition-layer" data-key={index} style={{transitionDelay: '0.3s'}}/>
                  </div>
                </TitleImageIntro>
              </React.Fragment>
            )
          }
          if (elem.format == "banner") {
            return (
              <ImageContent
                key={index}
                title={elem.title}
                subtitle={elem.subtitle}
                content={html}
                imageUrl={elem.imageUrl}
                parallaxImages={elem.parallaxImages}
              />
            );
          }
          if (elem.format == "titles") {
            return (
              <ListTitles
                key={index}
                tagheading={"h4"}
                titles={elem.titles}
                content={html}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
              />
            );
          }
          if (elem.format == "tabs") {
            return (
              <TabMethod
                key={index}
                title={elem.title}
                content={html}
                listtitle={elem.listtitle}
                listcontent={elem.listcontent}
                buttonlabel={elem.buttonlabel}
              />
            );
          }
        }
      )}
    </>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(/method/)/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            format
            title
            subtitle
            titles
            imageUrl
            parallaxImages { 
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              speed 
              direction 
              alt
            }
            buttonlabel
            listtitle
            listcontent
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
  }
`;
