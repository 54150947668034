import React from "react";
import { MethodTabsFrontMatter } from "types/graphql";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { Link } from "gatsby";
import Section from "../section";
import BaseButton from "components/button/BaseButton";
import classNames from "classnames/bind";
import ArrowRight from "assets/svg/arrow-right.svg";

type tabMethodProps = Omit<MethodTabsFrontMatter, "format"> & {
  content: string;
};

const tabMethod = ({
  title,
  content,
  listtitle,
  listcontent,
  buttonlabel,
}: tabMethodProps) => {
  const [value, setValue] = React.useState<number>(0);

  const handleClickPlausible = () => {
    if ("plausible" in window) {
      window.plausible("approche-start-project");
    }
  }

  return (
    <Section className="relative flex flex-col lg:pb-36" full>
      <span className="h2 mb-4 text-center block">{title}</span>
      <ul
        className={classNames("flex mb-6 gap-x-5 overflow-x-auto flex-nowrap", {
            "justify-center" :listtitle && listtitle.length <= 4 ,
            "md:justify-center" : listtitle && listtitle.length > 4,
          })
        }
      >
        {
          listtitle && listtitle.map((item: string, index: number) => {
            return (
              <li
                role="button"
                key={index}
                className={ classNames("w-16 h-16 lg:w-20 lg:h-20 bg-no-repeat relative inline-flex items-center justify-center flex-00a", {
                  'text-black': index === value,
                  'text-gray-600 hover:text-gray-900' : index !== value
                  })
                }
                onClick={() => setValue(index)}
              >
                <span className="h3 absolute pointer-events-none ">
                  {
                    listtitle.length === index + 1
                      ? (<span className="h2 top-0.5 relative">∞</span>)
                      : (index + 1)
                  }
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 95 93"
                  className={classNames(
                    "w-full h-full ", {
                      "fill-yellow stroke-black": index === value,
                      "stroke-gray-600 fill-gray-100 hover:stroke-gray-900": index !== value,
                    })
                  }
                >
                  {
                    listtitle.length === index + 1 ? (
                    <circle cx="47.837" cy="47" r="40" />
                    ) : (
                    <path d="M47.245 2.495C54.656 25.164 70.46 39.658 92.522 46.92c-22.06 7.267-37.866 21.747-45.278 44.415-7.41-22.668-23.217-37.15-45.277-44.415 22.061-7.26 37.87-21.751 45.278-44.424Z" />
                    )
                  }
                </svg>
              </li>
            );
          })
        }
      </ul>

      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={value}
          classNames='page-fade'
          timeout={200}
        >
          <article className="rounded-xl border border-gray-100 p-5 mb-6 max-w-2xl mx-auto">
            <h3 className="text-center mb-2">{listtitle ? listtitle[value] : ''}</h3>
            <p className="text-gray-700 text-b3 text-center">
              {listcontent ? listcontent[value] : ''}
            </p>
          </article>
        </CSSTransition>
      </SwitchTransition>

      {content && (
        <div
          className="mb-6"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {buttonlabel && (
        <div className="flex justify-center">
          <BaseButton
            onClick={() => handleClickPlausible()}
            tag={Link}
            to="/contact"
            size="md"
            target="_blank"
            rel="noopener"
            role="button"
            className="self-center arrow-left-in"
          >
            <ArrowRight />
            {buttonlabel}
          </BaseButton>
        </div>
      )}
    </Section>
  );
};

export default tabMethod;
